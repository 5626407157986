import Layout from "../../commons/Layout";
import PageTopDescription from "../../commons/PageTopDescription";

function TermsAndConditions() {
  let statements_data = [
    {
      id: 1,
      category: "Eligibility for Returns",
      items: [
        {
          id: 1,
          title: "Products eligible for returns must:",
          subItems: [
            {
              id: 1,
              text: "Be unused and in their original condition, including packaging and tags.",
            },
            {
              id: 2,
              text: "Have no signs of wear, damage, or modifications.",
            },
            {
              id: 3,
              text: "Be returned within 7 days of the delivery date.",
            },
          ],
        },
        {
          id: 2,
          title: "The following items are non-returnable:",
          subItems: [
            {
              id: 1,
              text: "Custom or bespoke furniture and accessories.",
            },
            {
              id: 2,
              text: `Items marked as "Discount" "Final Sale" or "Clearance."`,
            },
            {
              id: 3,
              text: "Gift cards or promotional items.",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      category: "Process for Returns",
      items: [
        {
          id: 1,
          title: "To initiate a return:",
          subItems: [
            {
              id: 1,
              text: "Contact our Customer Support Team via email at [info@wudify.com] or call [+234 802 456 7858].",
            },
            {
              id: 2,
              text: "Provide the order number, item details, and reason for return.",
            },
          ],
        },
        {
          id: 2,
          title:
            "Once approved, you will receive a return authorization number (RAN) and instructions for shipping the item back to us.",
          subItems: [],
        },
        {
          id: 3,
          title:
            "Customers are responsible for the cost of return shipping unless:",
          subItems: [
            {
              id: 1,
              text: "The item was damaged during delivery.",
            },
            {
              id: 2,
              text: "An incorrect item was shipped.",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      category: "Refunds",
      items: [
        {
          id: 1,
          title:
            "Refunds will be processed to the original payment method once the returned item is received and inspected.",
          subItems: [
            {
              id: 1,
              text: "Processing time: Refunds may take 7-10 business days to reflect on your account.",
            },
          ],
        },
        {
          id: 2,
          title:
            "If the item is returned damaged, incomplete, or in an unsellable condition:",
          subItems: [
            {
              id: 1,
              text: "A partial refund or denial of refund may be issued.",
            },
          ],
        },
        {
          id: 3,
          title:
            "For custom or bespoke orders, no refunds are provided unless there is a proven manufacturing defect. ",
          subItems: [],
        },
      ],
    },
    {
      id: 5,
      category: "Damaged or Defective Items",
      items: [
        {
          id: 1,
          title: "If your order arrives damaged or defective:",
          subItems: [
            {
              id: 1,
              text: "Notify us within 48 hours of delivery.",
            },
            {
              id: 2,
              text: "Provide photos of the damaged/defective product and packaging. ",
            },
          ],
        },
        {
          id: 2,
          title: "For approved claims:",
          subItems: [
            {
              id: 1,
              text: "We will offer a replacement, repair, or full refund at no extra cost to you.",
            },
          ],
        },
      ],
    },
    {
      id: 6,
      category: "Cancellations",
      items: [
        {
          id: 1,
          title:
            "Orders for in-stock items may be cancelled prior to shipment.",
          subItems: [
            {
              id: 1,
              text: "Contact our Customer Support Team immediately for assistance.",
            },
          ],
        },
        {
          id: 2,
          title:
            "Orders for custom or bespoke items cannot be canceled once production has begun.",
          subItems: [],
        },
      ],
    },
    {
      id: 7,
      category: "Disclaimers",
      items: [
        {
          id: 1,
          title:
            "Wudify Africa is not responsible for returns lost or damaged in transit. We recommend using a trackable shipping service and retaining proof of shipment.",
          subItems: [],
        },
        {
          id: 2,
          title:
            "Our policies comply with applicable consumer protection laws, which may vary by jurisdiction.",
          subItems: [],
        },
      ],
    },
  ];

  return (
    <Layout>
      <div className="w-[70%] max-lg:w-4/5 max-md:w-[90%] mx-auto py-12 max-md:py-6">
        <PageTopDescription
          heading={"Terms & Conditions on Returns and Refunds"}
          description="Welcome to Wudify! These terms and conditions outline the policy for returns and refunds for products purchased from our website. Please read them carefully. By purchasing from Wudify, you agree to the terms outlined below."
          subHeadingClassName="max-lg:!text-[32px] max-md:!text-[24px]"
        />
        <div className="mt-10"></div>

        {statements_data.map(({ id: categoryId, category, items }) => {
          return (
            <div key={categoryId} className="mb-8">
              <div className="flex gap-2 mb-2 text-[24px] max-lg:text-[20px] max-md:text-[16px] font-[600]">
                <div className="">{categoryId}.0</div>
                <div className="text-left">{category}:</div>
              </div>
              {items?.map(({ title, subItems, id }) => (
                <div key={id} className="mt-2 pl-4">
                  <div className="flex gap-2 text-[18px] max-lg:text-[14px] max-md:text-[13px] font-[500] mb-2 text-[#18181B]">
                    <div className="">{`${categoryId}.${id}`}.</div>
                    <div className="text-left">{title}</div>
                  </div>
                  {subItems.map(({ text, id }) => (
                    <div
                      key={id}
                      className="pl-4 text-[#52525B] text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[400] text-left mb-2"
                    >
                      - {text}
                    </div>
                  ))}
                  <hr />
                </div>
              ))}
            </div>
          );
        })}

        {/* Contact Information */}
        <div className="text-left mt-8">
          <div className="font-[600] text-[24px] max-lg:text-[20px] max-md:text-[16px] text-[#18181B]">
            Contact Information
          </div>
          <div className="text-[#52525B] font-[400] text-[16px] max-lg:text-[14px] max-md:text-[12px] my-4">
            For any inquiries regarding the shipping and returns of fragile
            items, please contact our specialized customer support team at
            info@wudify.com.
          </div>
          <div className="text-[#52525B] font-[400] text-[16px] max-lg:text-[14px] max-md:text-[12px]">
            We appreciate your trust in Wudify Africa, and we are committed to
            ensuring that your experience exceeds expectations, especially when
            it comes to your furniture, lighting, and home accessories needs.
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TermsAndConditions;
