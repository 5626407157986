import React from "react";

function ProductDetailsSectionLoader({}) {
  return (
    <div className="flex max-md:flex-col gap-10 items-start justify-between product-details-section-loader">
      {/* Left section */}
      <div className="w-1/2 max-md:w-full">
        {/* Main image */}
        <div className="h-[457px] max-md:h-[250px] w-full bg-gray-100"></div>

        {/* Other images */}
        <div className="flex gap-[20px] items-center justify-between w-full mt-[20px]">
          {/* <ProductThumbnailImage
            imagePath={product?.imageOne?.main}
            onSelect={setSelectedImage}
            selected={selectedImage === product?.images?.main}
          /> */}

          {[1, 2, 3, 4].map((x) => {
            return <div key={x} className="bg-gray-100 w-1/4 h-[100px]"></div>;
          })}
        </div>
      </div>

      <div className="w-1/2 max-md:w-full text-left mt-0 pt-0">
        <div className="">
          <h3 className="text-[28px] font-[600] mb-8">
            <p className="bg-gray-100 w-[12em] h-[30px]"></p>
          </h3>

          <p className="text-[28px] font-[600] bg-gray-100 w-[18em] h-[30px]"></p>
          <p className="text-[28px] font-[600] bg-gray-100 w-[18em] h-[30px] my-4"></p>
          <p className="text-[28px] font-[600] bg-gray-100 w-[12em] h-[30px] mb-12"></p>

          <div className="flex gap-3 mb-6 text-[14px] font-[500] my-6 text-[#667185]">
            <p className="bg-gray-100 w-[15em] h-[20px]"></p>
          </div>

          <div className="text-[28px] font-[600] mb-4">
            <p className="bg-gray-100 w-[5em] h-[20px]"></p>
          </div>

          <div className="text-[28px] font-[600] mb-4">
            <p className="bg-gray-100 w-[5em] h-[20px]"></p>
          </div>

          <div className="flex gap-10 mt-[6em]">
            <div className="w-[10em] h-[4em] rounded-full bg-gray-100"></div>
            <div className="w-[10em] h-[4em] rounded-full bg-gray-100"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetailsSectionLoader;
