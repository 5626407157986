import React, { useState } from "react";
import Layout from "../../commons/Layout";
import CardTitleBig from "../../commons/CardTitleBig";
import CustomInputField from "../../commons/CustomInputField";
import RoundedMdButton from "../../commons/RoundedMdButton";
import { Formik, Form } from "formik";
import useSignup from "../../hooks/useSignup";
import CustomSnackBar from "../../commons/CustomSnackBar";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import useGoogleLogin from "../../hooks/useGoogleLogin";
import GoogleLoginAuth from "./GoogleLoginAuth";
import { encryptData } from "./../../helpers/encryptor";
import { Link } from "react-router-dom";

function Signup() {
  const [page, setPage] = useState(1);
  const [nextClickable, setNextClickable] = useState(false);

  const { signUserUp } = useSignup();
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [checked, setChecked] = useState(false);

  const { googleLogUserIn } = useGoogleLogin();

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="flex items-center py-10">
        <div className="shadow-md rounded-lg  bg-white p-6 w-[32%] max-lg:w-3/5 max-md:w-4/5 mx-auto">
          <CardTitleBig title="Create a new account" />
          <Formik
            initialValues={{
              email: "",
              password: "",
              confirmPassword: "",
              phoneNumber: "",
              firstName: "",
              lastName: "",
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              signUserUp({
                email: encryptData(values.email),
                password: encryptData(values.password),
                phoneNumber: encryptData(values.phoneNumber),
                firstName: encryptData(values.firstName),
                lastName: encryptData(values.lastName),
              })
                .then((res) => {
                  setIsError(false);
                  resetForm();
                })
                .catch((err) => {
                  setSnackBarMessage(err?.message);
                  setIsError(true);
                  setOpenSnackBar(true);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Email is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              if (!values.password) {
                errors.password = "Password is required";
              } else if (!/\^*[A-Z]/i.test(values.password)) {
                errors.password = "Password should contain an uppercase";
              } else if (!/\^*[a-z]/.test(values.password)) {
                errors.password = "Password should contain a lowercase";
              } else if (!/\^*[0-9]/i.test(values.password)) {
                errors.password = "Password should contain a number";
              } else if (!/\^*[!@#$%^&*]/i.test(values.password)) {
                errors.password =
                  "Password should contain at least one special character";
              } else if (values.password.length < 8) {
                errors.password = "Password should be at least 8 characters";
              }
              if (!values.confirmPassword) {
                errors.confirmPassword = "Confirm password is required";
              }

              if (values.confirmPassword !== values.password) {
                errors.confirmPassword = "Passwords must match";
              }

              if (Object.keys(errors).length == 0) {
                setNextClickable(true);
              } else {
                setNextClickable(false);
              }

              if (!values.firstName) {
                errors.firstName = "First name is required";
              }
              if (!values.lastName) {
                errors.lastName = "Last name is required";
              }
              if (!values.phoneNumber) {
                errors.phoneNumber = "Mobile number is required";
              }

              return errors;
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {page === 1 && (
                  <>
                    <CustomInputField
                      label={"Email Address"}
                      type="email"
                      placeholder="Please enter your email address"
                      name="email"
                    />
                    <CustomInputField
                      label={"Password"}
                      type="password"
                      placeholder="Please enter your password"
                      name="password"
                    />
                    <CustomInputField
                      label={"Confirm Password"}
                      type="password"
                      placeholder="Please re-enter your password"
                      name="confirmPassword"
                    />
                    <RoundedMdButton
                      onClick={() => setPage(2)}
                      label="Next"
                      className="bg-[#FF9D21] text-white hover:bg-orange-600"
                      type="button"
                      disabled={!nextClickable}
                    />
                  </>
                )}
                {page === 2 && (
                  <>
                    <CustomInputField
                      label={"First Name"}
                      type="text"
                      placeholder="Please enter your first name"
                      name="firstName"
                      disabled={isSubmitting}
                    />
                    <CustomInputField
                      label={"Last Name"}
                      type="text"
                      placeholder="Please enter your last name"
                      name="lastName"
                      disabled={isSubmitting}
                    />
                    <CustomInputField
                      label={"Mobile Number"}
                      disabled={isSubmitting}
                      type="text"
                      placeholder="Please enter your mobile number"
                      name="phoneNumber"
                    />
                    <p className="font-[500] my-6 cursor-pointer text-[14px] max-md:text-[12px] flex items-center justify-between w-full">
                      <label
                        className="w-full flex gap-2 items-center"
                        for="termsAndCondition"
                      >
                        <input
                          type="checkbox"
                          id="termsAndCondition"
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                          className="w-[14px] h-[14px] opacity-80 cursor-pointer"
                        />
                        <span>
                          I have read the{" "}
                          <Link
                            target="_blank"
                            className="underline text-[#FF9D21]"
                            to={"/terms-and-conditions"}
                          >
                            Terms & Conditions
                          </Link>{" "}
                          and agree
                        </span>
                      </label>
                    </p>
                    <div className="flex justify-between gap-3">
                      <RoundedMdButton
                        label="Back"
                        onClick={() => setPage(1)}
                        disabled={isSubmitting}
                        className="bg-white text-[#FF9D21] hover:opacity-80 border"
                        type="button"
                      />
                      <RoundedMdButton
                        label={isSubmitting ? "Please wait" : "Create account"}
                        disabled={isSubmitting || !checked}
                        className="bg-[#FF9D21] text-white hover:bg-orange-600"
                        type="submit"
                        left={false}
                      >
                        {isSubmitting && (
                          <CustomThreeDotsSpinner
                            height="20"
                            width="20"
                            color="white"
                          />
                        )}
                      </RoundedMdButton>
                    </div>
                  </>
                )}
                {page === 1 && !isSubmitting && (
                  <>
                    <div className="flex gap-2 w-full items-center my-4">
                      <div className="w-[48%]">
                        <hr />
                      </div>
                      <div>Or</div>
                      <div className="w-[48%]">
                        <hr />
                      </div>
                    </div>

                    <GoogleLoginAuth
                      disabled={isSubmitting}
                      handleLogin={googleLogUserIn}
                    ></GoogleLoginAuth>
                    {/* <RoundedMdButton
                      label="Continue with Facebook"
                      className="bg-white text-black border"
                    >
                      <FaFacebook className="text-[20px] text-blue-800" />
                    </RoundedMdButton> */}

                    <div className="flex gap-2 items-center justify-center">
                      <p className="text-gray-400">Already have an account? </p>
                      <p className="font-[500] text-[#FF9D21] my-5 cursor-pointer">
                        <a href="/auth/login">Log in</a>
                      </p>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </Layout>
  );
}

export default Signup;
