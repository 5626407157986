import React from "react";
import { getDateTime } from "../../helpers/dateTime";
import { getFileUrl } from "../../helpers/extractors";

function AccountPendingReviewsItem({
  orderItem,
  onViewClick,
  buttonText = "Rate this product",
  setSelectedItem,
}) {
  return (
    <>
      <div className="flex flex-wrap justify-between mb-6">
        <div className="flex gap-4 max-md:gap-2 items-center max-md:items-start">
          <div className="h-[120px] w-[120px] max-md:w-[60px] max-md:h-[60px]">
            <img
              src={orderItem?.Product?.imageOne}
              className="h-full w-full"
              alt=""
              style={{ objectFit: "cover" }}
              loading="lazy"
            />
          </div>
          <div className="flex flex-col justify-between items-start gap-1 py-1">
            <p className="text-[14px] max-md:text-[12px] font-[500]">
              <span className="text-[#475367]"></span>Order Number:{" "}
              <span className="text-[#FF9D21]">{orderItem.orderNumber}</span>
            </p>
            <p className="text-[18px] max-md:text-[14px] font-[600]">
              {orderItem?.Product?.name}
            </p>
            <p className="text-[14px] max-md:text-[12px] font-[600]">
              Amount: N{orderItem.total}
            </p>
            <p className="text-[#475367] text-[14px] max-md:text-[12px] font-[400]">
              Color: {orderItem.color}
            </p>
            <p className="text-[#475367] text-[14px] max-md:text-[12px] font-[400]">
              <span>{getDateTime(orderItem.createdAt)}</span>
            </p>
          </div>
        </div>
        <div className="flex justify-end mt-3">
          <p
            className="mb-3 text-[14px] max-md:text-[12px] font-[500]"
            onClick={() => {
              setSelectedItem(orderItem);
            }}
          >
            <span className="text-[#FF9D21] bg-orange-100 py-1 px-3 rounded-full mb-3 cursor-pointer">
              {buttonText}
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

export default AccountPendingReviewsItem;
