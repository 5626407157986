import { Link } from "react-router-dom";

function FooterLinkItem({ url, label }) {
  return (
    <Link
      to={url}
      className="cursor-pointer font-[400] text-[16px] max-lg:text-[14px] max-md:text-[12px] hover:text-[#FF9D21]"
    >
      {label}
    </Link>
  );
}

export default FooterLinkItem;
