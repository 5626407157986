import React from "react";
import { getDateTime } from "../../helpers/dateTime";
import { getFileUrl } from "./../../helpers/extractors";
import CustomPriceDisplay from "../../commons/CustomPriceDisplay";

function AccountOrdersItem({
  orderItem,
  onViewClick,
  buttonText = "View Details",
  onInitiateClick,
  setSelectedItem,
}) {
  return (
    <>
      <div className="flex justify-between mb-6 max-sm:flex-col">
        <div className="flex gap-4 items-center max-md:items-start">
          <div className="h-[120px] w-[120px] max-md:w-[60px] max-md:h-[60px]">
            <img
              src={orderItem?.Product?.imageOne}
              className="h-full w-full"
              alt=""
              style={{ objectFit: "cover" }}
              loading="lazy"
            />
          </div>
          <div className="flex flex-col justify-between items-start gap-1 py-1">
            <p className="text-[14px] max-md:text-[12px] font-[500]">
              <span className="text-[#475367]"></span>Order Number:{" "}
              <span className="text-[#FF9D21]">{orderItem?.orderNumber}</span>
            </p>
            <p className="text-[18px] max-md:text-[14px] font-[600]">
              {orderItem?.Product?.name}
            </p>
            <p className="text-[#475367] text-[14px] max-md:text-[12px] font-[400]">
              Color: {orderItem?.color} <span>{orderItem?.datePurchased}</span>
            </p>

            {(!orderItem?.hasReturnRequest ||
              orderItem?.hasReturnRequest == "false" ||
              orderItem?.hasReturnRequest == false) && (
              <p
                onClick={onInitiateClick}
                className="text-[#FF9D21] text-[14px] max-md:text-[12px] font-[500] cursor-pointer"
                style={{ textDecoration: "underline" }}
              >
                Initiate return
              </p>
            )}
            {(orderItem?.hasReturnRequest == "true" ||
              orderItem?.hasReturnRequest == true) && (
              <p
                className="text-[14px] max-md:text-[12px] font-[500] cursor-pointer"
                style={{ textDecoration: "" }}
              >
                Return request status:{" "}
                <span className="text-[#FF9D21] capitalize">
                  {orderItem?.returnRequest?.status}
                </span>
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col items-end max-sm:items-start mt-4">
          <p
            className="mb-3 text-[14px] max-md:text-[12px] font-[500]"
            onClick={() => {
              if (onViewClick) {
                onViewClick();
              } else if (setSelectedItem) {
                setSelectedItem(orderItem);
              }
              // onViewClick();
            }}
          >
            <span className="text-[#FF9D21] bg-orange-100 py-1 px-3 rounded-full mb-3 cursor-pointer">
              {buttonText}
            </span>
          </p>
          <p className="text-[16px] max-md:text-[12px] font-[600] flex gap-1">
            Amount: <CustomPriceDisplay price={orderItem?.total} />
          </p>
          <p className="text-[#475367] text-[14px] max-md:text-[12px] font-[400]">
            <span>{getDateTime(orderItem?.createdAt)}</span>
          </p>
          {orderItem?.hasReturnRequest === "true" && (
            <p
              className={`${
                orderItem?.returnRequest?.status === "declined"
                  ? "text-red-600"
                  : orderItem?.returnRequest?.status === "accepted"
                  ? "text-green-600"
                  : "text-[#FF9D21]"
              } text-[14px] max-md:text-[12px] font-[500]`}
            >
              <span>
                Your return request is {orderItem?.returnRequest?.status}
              </span>
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default AccountOrdersItem;
