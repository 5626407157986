import React from "react";
import { IoIosArrowRoundForward } from "react-icons/io";
import HeadingTitle from "../../commons/HeadingTitle";
import ProductItemMiniLoaders from "../../commons/loaders/ProductItemMiniLoaders";
import ProductItemMini from "../../commons/ProductItemMini";
import ViewAllButton from "../../commons/ViewAllButton";
import useProducts from "../../hooks/useProducts";
import { Link } from "react-router-dom";

function ProductSection() {
  const { products: fetchedProducts, isLoading } = useProducts();

  return (
    <div className="bg-white mx-auto w-[95%] mt-16">
      {/* Featured Products */}
      <div className="flex justify-between items-center mb-8">
        <HeadingTitle title="Featured Products" />
        <Link to={"/categories/furniture"}>
          <ViewAllButton>
            <IoIosArrowRoundForward className="text-lg max-md:text-sm" />
          </ViewAllButton>
        </Link>
      </div>

      {isLoading && (
        <>
          <ProductItemMiniLoaders number={4} />
        </>
      )}

      {!isLoading && fetchedProducts && (
        <div className="grid grid-cols-4 max-md:grid-cols-3 max-sm:grid-cols-2 gap-x-4 gap-y-8">
          {fetchedProducts?.slice(0, 12)?.map((product) => {
            return <ProductItemMini product={product} />;
          })}
        </div>
      )}

      <div className="my-16"></div>

      {/* Best Sellers */}
      <div className="flex justify-between items-center mb-4">
        <HeadingTitle title="Best Sellers" />
        <Link to={"/categories/furniture"}>
          <ViewAllButton>
            <IoIosArrowRoundForward className="text-lg" />
          </ViewAllButton>
        </Link>
      </div>

      {isLoading && (
        <>
          <ProductItemMiniLoaders number={4} />
        </>
      )}

      {!isLoading && fetchedProducts && (
        <div className="grid grid-cols-4 max-md:grid-cols-3 max-sm:grid-cols-2 gap-x-4 gap-y-8">
          {fetchedProducts
            ?.sort((a, b) => b.purchaseScore - a.purchaseScore)
            ?.slice(0, 12)
            .map((product) => {
              return <ProductItemMini product={product} />;
            })}
        </div>
      )}

      <div className="my-16"></div>

      {/* New Arrival Products */}
      <div className="flex justify-between items-center mb-4">
        <HeadingTitle title="New Arrival Products" />
        <Link to={"/categories/furniture"}>
          <ViewAllButton>
            <IoIosArrowRoundForward className="text-lg" />
          </ViewAllButton>
        </Link>
      </div>

      {isLoading && (
        <>
          <ProductItemMiniLoaders number={4} />
        </>
      )}

      {!isLoading && fetchedProducts && (
        <div className="grid grid-cols-4 max-md:grid-cols-3 max-sm:grid-cols-2 gap-x-4 gap-y-8">
          {fetchedProducts
            ?.filter((f) => f.isNewArrival)
            ?.slice(0, 12)
            .map((product) => {
              return <ProductItemMini product={product} />;
            })}
        </div>
      )}
    </div>
  );
}

export default ProductSection;
