function ImageBuilder({ numberOfImages = 3, imageList = [] }) {
  return (
    <div
      className={`grid grid-cols-4 max-md:grid-cols-2 gap-4 mb-3 max-md:mb-1`}
    >
      {imageList.map((image, id) => {
        return (
          <div
            key={id}
            className={`bg-red-100 h-[300px] max-lg:h-[240px] max-md:h-[180px]`}
          >
            <img
              className={"w-full h-full object-cover"}
              src={image?.path}
              alt="subcategory"
            />
          </div>
        );
      })}
    </div>
  );
}

export default ImageBuilder;
