import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const initiateReturn = async (data) => {
  console.log("data", data);
  return axiosInstance(`/return-requests`, "POST", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const getOrderReturnRequest = async (data) => {
  return axiosInstance(`/return-requests?orderId=${data}`, "GET", null, null)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};
