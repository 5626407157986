import { useMutation, useQueryClient } from "@tanstack/react-query";
import { USER_QUERY_KEY } from "../constants/queryKeys";
import { logout } from "../services/auth-service";
import { removeIntendedRoute } from "../local_store/intendedRouteLocalStore";
import * as userLocalStore from "../local_store/userLocalStore";

function useLogout() {
  const queryClient = useQueryClient();
  const {
    mutateAsync: logUserOut,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (logoutData) => logout(logoutData),
    onSuccess: (data) => {
      userLocalStore.removeUser();
      queryClient.setQueryData([USER_QUERY_KEY], null);
      removeIntendedRoute();
    },
    onError: (error) => {
      queryClient.setQueryData([USER_QUERY_KEY], null);
      removeIntendedRoute();
    },
  });
  return {
    logUserOut,
    isLoading,
    error,
    data,
  };
}

export default useLogout;
