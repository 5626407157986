import React from "react";

function CarouselArrow({ children, onClick, className }) {
  return (
    <div
      onClick={onClick}
      className={`${className} cursor-pointer p-2 max-md:p-1 flex items-center justify-center rounded-full bg-gray-300 hover:bg-black hover:text-white`}
    >
      {children}
    </div>
  );
}

export default CarouselArrow;
