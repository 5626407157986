import { LuSearchX } from "react-icons/lu";

function NotFoundSVG() {
  return (
    <div>
      <LuSearchX className="text-[10em] max-lg:text-[8em] max-md:text-[6em] max-sm:text-[4em] text-gray-400" />
    </div>
  );
}

export default NotFoundSVG;
