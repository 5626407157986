function ProductThumbnailImage({ imagePath, onSelect, selected }) {
  return (
    <div
      className={`${
        selected ? "border border-orange-400" : ""
      } hover:border-orange-400 hover:border hover:opacity-80 cursor-pointer`}
      onClick={() => onSelect(imagePath)}
    >
      <img
        className="w-full h-[8em] max-md:h-[6em] object-cover"
        src={imagePath}
        alt=""
        loading="lazy"
      />
    </div>
  );
}

export default ProductThumbnailImage;
