import React from "react";
import CardTitle from "../../commons/CardTitle";
import { MdOutlineArrowBackIos } from "react-icons/md";
import AccountOrdersItem from "./AccountOrdersItem";
import { getDateTime } from "../../helpers/dateTime";
import { useNavigate } from "react-router-dom";
import useUserOrder from "../../hooks/useUserOrder";
import useUser from "../../hooks/useUser";
import CustomBackdrop from "../../commons/CustomBackdrop";

function AccountOrderDetails({ onBackClick, orderItem, onInitiateClick }) {
  const navigate = useNavigate();
  const shippingAddress = JSON.parse(
    orderItem?.shippingAddress ?? '{ "address": "" }'
  );

  const { user } = useUser();

  const { userOrderItem, isLoading } = useUserOrder(user, orderItem?.id);

  return (
    <div className="shadow-md rounded-lg w-[70%] max-lg:w-full bg-white p-6 text-left">
      {isLoading && <CustomBackdrop text={"Please wait..."} />}
      <div className="flex justify-between items-start">
        <div className="">
          <CardTitle title="Order Details" />
        </div>
        <div
          onClick={onBackClick}
          className="flex gap-2 text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[600] items-center text-[#318EC9] cursor-pointer"
        >
          <div>
            <MdOutlineArrowBackIos />
          </div>
          <div>Back</div>
        </div>
      </div>

      {/* Order details */}
      <div className="">
        {/* First level */}
        <div className="flex flex-col gap-2">
          <div className="flex justify-between font-[600] text-[14px] max-md:text-[12px] items-center">
            <div>Order Information</div>
            {/* <div className="text-[#FF9D21] flex items-center gap-2 cursor-pointer">
              <div>
                <LocationIcon className="text-[20px]" />
              </div>
              <div>Track Order</div>
            </div> */}
          </div>
          <div className="flex justify-between items-center text-[#828282] text-[14px] max-md:text-[12px] font-[500]">
            <div>Order Number</div>
            <div className="text-[#FF9D21] ">{userOrderItem?.orderNumber}</div>
          </div>
          <div className="flex justify-between items-center text-[#828282] text-[14px] max-md:text-[12px] font-[500]">
            <div>Order Date</div>
            <div>{getDateTime(userOrderItem?.createdAt)}</div>
          </div>
          <div className="flex justify-between items-center text-[#828282] text-[14px] max-md:text-[12px] font-[500]">
            <div>Sold By</div>
            <div
              className="text-[#FF9D21]"
              style={{ textDecoration: "underline" }}
            >
              {userOrderItem?.Product?.Vendor?.businessName}
            </div>
          </div>
          {/* <div className="flex justify-between items-center text-[#828282] text-[14px] font-[500]">
            <div>Tracking Id</div>
            <div style={{ textDecoration: "underline" }}>AAB-12020</div>
          </div> */}
        </div>
        <div className="mt-6"></div>
        <hr />
        <div className="mt-6"></div>

        {/* Second level */}
        <div className="flex flex-col gap-2">
          <div className="flex justify-between font-[600] text-[14px] max-md:text-[12px] items-center">
            <div>Delivery Information</div>
          </div>
          <div className="flex justify-between items-center text-[#828282] text-[14px] max-md:text-[12px] font-[500]">
            <div>Receipts Name</div>
            <div>
              {userOrderItem?.User.firstName} {userOrderItem?.User.lastName}
            </div>
          </div>
          <div className="flex justify-between items-center text-[#828282] text-[14px] max-md:text-[12px] font-[500]">
            <div>Delivery Address</div>
            <div>{shippingAddress?.address}</div>
          </div>
          <div className="flex justify-between items-center text-[#828282] text-[14px] max-md:text-[12px] font-[500]">
            <div>Delivery Fee</div>
            <div>₦{userOrderItem?.shipping}</div>
          </div>
          <div className="flex justify-between items-center text-[#828282] text-[14px] max-md:text-[12px] font-[500]">
            <div>Total Number</div>
            <div>₦{userOrderItem?.total}</div>
          </div>
          <div className="flex justify-between items-center text-[#828282] text-[14px] max-md:text-[12px] font-[500]">
            <div>Payment Method</div>
            <div>{userOrderItem?.paymentMethod}</div>
          </div>
        </div>

        <div className="mt-6"></div>
        <hr />
        <div className="mt-6"></div>

        <AccountOrdersItem
          orderItem={userOrderItem}
          buttonText="Reorder"
          onViewClick={() => {
            navigate(`/products/${userOrderItem?.Product?.slug}`);
            navigate(0);
          }}
          onInitiateClick={onInitiateClick}
        />
      </div>

      <div className="mb-10"></div>
    </div>
  );
}

export default AccountOrderDetails;
