import React from "react";
import { getFileUrl } from "../../helpers/extractors";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";

function HeroItem({ heroIndex, selectionIndex, hero }) {
  return (
    <div
      className={`relative rounded-sm block`}
      style={{
        minWidth: "100%",
        minHeight: "100%",
      }}
    >
      <img
        src={getFileUrl(hero.imageUrl)}
        alt="Imag"
        className="h-full w-full object-cover rounded-sm"
        // loading="lazy"
      />
      <Fade bottom>
        <div className="absolute top-[170px] max-md:top-[120px] w-full">
          <div className="mb-12 max-lg:mb-8 max-md:mb-4">
            <span className="text-white py-1.5 px-2.5 bg-[#FF9D21] rounded-full">
              {hero.extraText}
            </span>
          </div>
          <p className="text-white font-[500] text-[6em] max-lg:text-[4em] max-md:text-[2em] mb-12 max-lg:mb-8 max-md:mb-4">
            {hero.title}
          </p>
          <Link to={"/categories/furniture"}>
            <button className="bg-white text-black rounded-md py-3 px-6 max-md:px-3 max-md:py-2 font-semibold hover:bg-black hover:text-white">
              {hero.buttonText}
            </button>
          </Link>
        </div>
      </Fade>
    </div>
  );
}

export default HeroItem;
