import { useQuery } from "@tanstack/react-query";
import React from "react";
import BrandThumbnailItem from "../../commons/BrandThumbnailItem";
import { getBrands } from "../../services/brands-services";

function BrandSection() {
  let brands_data = [];

  // Queries
  const brands = useQuery({
    queryKey: ["brands"],
    queryFn: async () => {
      const result = await getBrands();
      if (result.data.error) {
      }
      return result;
    },
    onError: (error) => {
      console.log("error when fetching brands", error);
    },
  });

  brands_data = brands?.data?.data?.data;

  return (
    <div className="mx-auto w-[95%]">
      <div className="flex max-md:flex-wrap items-center justify-center gap-10 max-lg:gap-6 max-md:gap-4 my-12">
        {brands_data?.slice(0, 6)?.map((brand, id) => {
          return <BrandThumbnailItem key={id} brand={brand} />;
        })}
      </div>
    </div>
  );
}

export default BrandSection;
