import { useQuery } from "@tanstack/react-query";
import { ORDER_ITEM_QUERY_KEY } from "../constants/queryKeys";
import { fetchUserOrderItem } from "../services/order-services";

function useUserOrder(user, id) {
  const {
    data: userOrderItem,
    isLoading,
    error,
  } = useQuery({
    queryKey: [ORDER_ITEM_QUERY_KEY, id],
    queryFn: async () => {
      const result = await fetchUserOrderItem({
        accessToken: user?.accessToken,
        id,
      });
      return result?.data?.data;
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    userOrderItem,
    isLoading,
    error,
  };
}

export default useUserOrder;
