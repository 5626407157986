import React from "react";
import { getFileUrl } from "../../helpers/extractors";
import { Zoom } from "react-reveal";

function BlogSection({ title, description, images = [] }) {
  return (
    <div className="mt-8">
      <h3 className="text-[18px] max-md:text-[14px] font-[600] mt-8 mb-3">
        {title}
      </h3>
      <p className="text-[14px] max-md:text-[12px] font-[500] text-[#98A2B3]">
        {description}
      </p>
      <Zoom>
        <div className="flex items-center gap-4 max-md:gap-1 mt-5">
          {images.map((image) => {
            return (
              <img
                src={getFileUrl(image)}
                alt=""
                className="h-[340px]"
                style={{ objectFit: "cover", width: 100 / images.length + "%" }}
              />
            );
          })}
        </div>
      </Zoom>
    </div>
  );
}

export default BlogSection;
