import React from "react";

function QuestionAndAnswer({
  number,
  question,
  answer,
  answerTwo,
  answerThree,
  answerFour,
  answerFive,
}) {
  return (
    <div className="mt-4">
      <div className="flex gap-2 text-[20px] max-lg:text-[16px] max-md:text-[14px] font-[600] mb-2 text-[#18181B] text-left">
        <div className="">{number}.</div>
        <div>{question}</div>
      </div>
      <div className="text-[#52525B] text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[400] text-left mb-2">
        {answer}
      </div>
      <div className="text-[#52525B] text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[400] text-left mb-2">
        {answerTwo}
      </div>
      <div className="text-[#52525B] text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[400] text-left mb-2">
        {answerThree}
      </div>
      <div className="text-[#52525B] text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[400] text-left mb-2">
        {answerFour}
      </div>
      <div className="text-[#52525B] text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[400] text-left mb-2">
        {answerFive}
      </div>
      <hr />
    </div>
  );
}

export default QuestionAndAnswer;
