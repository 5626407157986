import React from "react";
import ProductItemMini from "../../commons/ProductItemMini";
import Pagination from "../../commons/Pagination";
import PaginationInfo from "../../commons/PaginationInfo";
import { MOST_POPULAR, NEW_TO_OLD, OLD_TO_NEW } from "../../constants/texts";
import { handleSortInputChange } from "../../helpers/filter";
import ProductItemMiniLoaders from "../../commons/loaders/ProductItemMiniLoaders";
import CustomNoRecordFound from "../../commons/CustomNoRecordFound";
import NotFoundSVG from "../../commons/NotFoundSVG";
import { useNavigate } from "react-router-dom";

function RightContent({
  subcategory,
  totalRecord,
  pageSize,
  pageNumber,
  setPageNumber,
  setPageSize,
  dispatch,
  filters,
  products,
  priceMax,
  isLoading,
}) {
  const navigate = useNavigate();

  return (
    <div>
      {!isLoading && products && products?.length > 0 && (
        <div className="text-[15px] max-md:text-[12px] font-[400] flex gap-2 flex-wrap justify-between items-center py-4 border-y-[1px] border-gray-200">
          <PaginationInfo
            pageNumber={pageNumber}
            pageSize={pageSize}
            totalRecord={totalRecord}
          />
          <div className="flex gap-2">
            <div>Sort By:</div>
            <div>
              <select
                onChange={(e) => handleSortInputChange(e, dispatch)}
                value={filters.productSortFilter}
                className="outline-none text-[#FF9D21] cursor-pointer"
              >
                <option value={MOST_POPULAR}>Most Popular</option>
                <option value={NEW_TO_OLD}>New to Old</option>
                <option value={OLD_TO_NEW}>Old to New</option>
              </select>
            </div>
          </div>
        </div>
      )}

      {!isLoading && products && products.length > 0 && (
        <div className="grid grid-cols-3 max-lg:grid-cols-2 gap-4">
          {products?.map((product) => {
            return <ProductItemMini product={product} />;
          })}
        </div>
      )}

      {!isLoading && products && products.length === 0 && (
        <div className="flex items-center justify-center py-24 max-md:py-12">
          <CustomNoRecordFound
            showButton={true}
            onButtonClick={() => navigate("/")}
            buttonText={"Got it"}
            label={"No Records Found"}
          >
            <NotFoundSVG />
          </CustomNoRecordFound>
        </div>
      )}

      {isLoading && (
        <>
          <ProductItemMiniLoaders number={3} />
          <div className="my-4"></div>
          <ProductItemMiniLoaders number={3} />
        </>
      )}

      {!isLoading && products && products.length > 0 && (
        <div className="mt-4 mb-5">
          <hr className="mt-10 mb-4" />
          <Pagination
            onPageChange={setPageNumber}
            currentPage={pageNumber}
            totalCount={totalRecord ?? 0}
            pageSize={pageSize}
          />
        </div>
      )}
    </div>
  );
}

export default RightContent;
