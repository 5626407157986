import React, { useState, useEffect, useCallback } from "react";
import { IoTrashBinOutline } from "react-icons/io5";
import QuantityAdjuster from "../../commons/QuantityAdjuster";
import ViewAllButton from "../../commons/ViewAllButton";
import useUpdateCartItem from "../../hooks/useUpdateCartItem";
import useUser from "../../hooks/useUser";
import { debounce } from "lodash";
import useDeleteCartItem from "../../hooks/useDeleteCartItem";
import CartItemLoader from "../../commons/loaders/CartItemLoader";
import { getColorArray, getFileUrl } from "../../helpers/extractors";
import ColorCircle from "../../commons/ColorCircle";
import useAddToWishlist from "../../hooks/useAddToWishlist";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import AddToWishlistButton from "../../commons/AddToWishlistButton";
import CustomOutOfStockDisplay from "../../commons/CustomOutOfStockDisplay";
import CustomPriceDisplay from "../../commons/CustomPriceDisplay";
import useCustomInfoDisplay from "../../hooks/useCustomInfoDisplay";

function CartItem({
  item,
  setIsError,
  setSnackBarMessage,
  setOpenSnackBar,
  setCartUpdated,
}) {
  const [quantity, setQuantity] = useState(0);
  const [selectedColor, setColor] = useState("");
  const { user } = useUser();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { updateCartItem, isLoading } = useUpdateCartItem();
  const { addToUserWishlist } = useAddToWishlist();
  const { deleteCartItem } = useDeleteCartItem();

  const { setInfoDetails, InfoDisplayComponent } = useCustomInfoDisplay();

  const handleSetQuantity = (value, color) => {
    setQuantity(value);
    setIsUpdating(true);
    updateCartDetails(value, color);
  };

  const handleChangeColor = (quantity, color) => {
    setColor(color);
    setIsUpdating(true);
    updateCartDetails(quantity, color);
  };

  const updateCartDetails = (quantity, color) => {
    setIsUpdating(true);
    updateCartItem({
      userId: user?.id,
      accessToken: user?.accessToken,
      quantity,
      productId: item?.Product.id,
      id: item?.id,
      color,
    })
      .then(() => {
        setInfoDetails({ message: "Updated", isError: false });
      })
      .catch((err) => {
        setInfoDetails({ message: err?.message, isError: true });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const handleDeleteItem = () => {
    setIsDeleting(true);
    deleteCartItem({
      userId: user?.id,
      accessToken: user?.accessToken,
      id: item?.id,
    })
      .then(() => {
        setOpenSnackBar(true);
        setSnackBarMessage("Successfully deleted");
        setIsError(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const debounced = useCallback(debounce(handleSetQuantity, 500), []);
  const debouncedColor = useCallback(debounce(handleChangeColor, 500), []);

  useEffect(() => {
    setQuantity(item?.quantity);
    setColor(item?.color);
  }, [item]);
  return (
    <>
      {!isDeleting && !isUpdating && (
        <>
          {item?.Product?.quantity > 0 && (
            <div className="flex justify-between gap-4">
              <div className="flex gap-3 w-1/2">
                <div className="h-[120px] w-[120px] max-md:h-[80px] max-md:w-[80px]">
                  <img
                    className="w-full h-full rounded-lg"
                    style={{ objectFit: "cover" }}
                    src={item?.Product?.imageOne}
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="flex flex-col justify-around">
                  <p className="font-[600] text-[18px] max-lg:text-[15px] max-md:text-[12px]">
                    {item?.Product?.name}
                  </p>
                  <p className="font-[500] text-[14px] max-lg:text-[12px] max-md:text-[10px]">
                    Installation fee
                  </p>
                  <p className="font-[400] text-[14px] max-lg:text-[12px] max-md:text-[10px] text-[#475367]">
                    {item?.Product?.colors && (
                      <>
                        <span>Color : </span>
                        <span
                          className="font-[500]"
                          style={{ color: selectedColor }}
                        >
                          {selectedColor?.toUpperCase()}
                        </span>
                      </>
                    )}
                  </p>
                  <p className="font-[400] text-[14px] max-lg:text-[12px] max-md:text-[10px] text-[#475367]">
                    {item?.Product?.colors && (
                      <>
                        <div className="flex gap-1">
                          {getColorArray(item?.Product?.colors)?.map(
                            (color) => {
                              return (
                                <ColorCircle
                                  style={{ backgroundColor: color }}
                                  value={color}
                                  colorHex={color}
                                  selectedColor={selectedColor}
                                  getColor={(e) =>
                                    debouncedColor(quantity, color)
                                  }
                                >
                                  {color}
                                </ColorCircle>
                              );
                            }
                          )}
                        </div>
                      </>
                    )}
                  </p>
                </div>
              </div>

              <div className="flex gap-2 w-1/2">
                <div className="flex gap-1 flex-col justify-around text-right">
                  <p className="font-[600] text-[18px] max-lg:text-[15px] max-md:text-[12px]">
                    <CustomPriceDisplay
                      text={"Amount: "}
                      price={
                        item?.Product ? item.Product?.priceAfterDiscount : 0
                      }
                    />
                  </p>
                  <p className="font-[500] text-[14px] max-lg:text-[12px] max-md:text-[10px]">
                    <CustomPriceDisplay
                      text={"Amount: "}
                      price={item?.Product?.installationFee ?? 0}
                    />
                  </p>
                  <div className="flex flex-wrap gap-2 justify-end">
                    <QuantityAdjuster
                      setQuantityValue={(e) => debounced(e, selectedColor)}
                      quantityValue={quantity}
                      maxValue={item?.Product.quantity}
                      className={`font-[500] text-[14px] max-md:text-[12px] w-1/3 max-md:w-2/5 py-1 px-2`}
                    />

                    {/* <ViewAllButton
                    text={isAdding ? "Please wait" : "Add to wishlist"}
                    onClick={handleAddToWishlist}
                    disabled={isAdding}
                    className="border-orange-400 text-[#FF9D21] hover:text-white hover:bg-[#FF9D21] border border-orange-400"
                  >
                    {isAdding && (
                      <CustomThreeDotsSpinner
                        height="20"
                        width="20"
                        color="white"
                      />
                    )}
                  </ViewAllButton> */}
                    <AddToWishlistButton
                      color={selectedColor}
                      productId={item?.Product?.id}
                      quantity={quantity}
                    />
                  </div>
                </div>
              </div>

              <div className="flex-col flex w-[4%]" onClick={handleDeleteItem}>
                <IoTrashBinOutline className="text-[20px] max-lg:text-[16px] max-md:text-[12px] mt-10 cursor-pointer text-red-600" />
              </div>
            </div>
          )}
          {item?.Product?.quantity < 1 && (
            <div className="flex justify-between">
              <div className="flex gap-3 w-[70%]">
                <div className="h-[120px] w-[120px]">
                  <img
                    className="w-full h-full rounded-lg"
                    style={{ objectFit: "cover" }}
                    src={item?.Product?.imageOne}
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="flex flex-col justify-around">
                  <p className="font-[600] text-[18px]">
                    {item?.Product?.name}
                  </p>
                  <p className="font-[500] text-[14px]">Installation fee</p>
                  <p className="font-[400] text-[14px] text-[#475367]">
                    {item?.Product?.colors && (
                      <>
                        <span>Color : </span>
                        <span
                          className="font-[500]"
                          style={{ color: selectedColor }}
                        >
                          {selectedColor?.toUpperCase()}
                        </span>
                      </>
                    )}
                  </p>
                  <p className="font-[400] text-[14px] max-md:text-[12px] text-[#475367]">
                    {item?.Product?.colors && (
                      <>
                        <div className="flex gap-1">
                          {getColorArray(item?.Product?.colors)?.map(
                            (color) => {
                              return (
                                <ColorCircle
                                  style={{ backgroundColor: color }}
                                  value={color}
                                  colorHex={color}
                                  selectedColor={selectedColor}
                                  getColor={(e) =>
                                    debouncedColor(quantity, color)
                                  }
                                >
                                  {color}
                                </ColorCircle>
                              );
                            }
                          )}
                        </div>
                      </>
                    )}
                  </p>
                </div>
              </div>

              <div className="flex w-[60%] justify-end">
                <div></div>
                <div className="flex flex-col justify-around text-right pr-8">
                  <p className="font-[600] text-[18px] max-lg:text-[15px] max-md:text-[12px]">
                    Amount: N
                    {item?.Product ? item.Product?.priceAfterDiscount : 0}
                  </p>
                  <p className="font-[500] text-[14px] max-lg:text-[12px] max-md:text-[10px]">
                    Amount: N{item?.Product?.installationFee ?? 0}
                  </p>
                  <div className="flex gap-3 justify-end">
                    <div></div>
                    <CustomOutOfStockDisplay
                      quantity={item?.Product?.quantity}
                      inCart={true}
                    />
                  </div>
                </div>
              </div>

              <div className="flex-col flex w-[4%]" onClick={handleDeleteItem}>
                <IoTrashBinOutline className="text-[20px] mt-10 cursor-pointer text-red-600" />
              </div>
            </div>
          )}
        </>
      )}
      {(isDeleting || isUpdating) && <CartItemLoader />}
    </>
  );
}

export default CartItem;
