import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { USER_QUERY_KEY } from "../constants/queryKeys";
import { getUser } from "../services/auth-service";
import * as userLocalStore from "../local_store/userLocalStore";
import { decryptData, decryptUser } from "../helpers/encryptor";

function useUser() {
  const { data: user } = useQuery({
    queryKey: [USER_QUERY_KEY],
    queryFn: async () => {
      const result = await getUser({
        id: decryptData(user?.id),
        accessToken: decryptData(user?.accessToken),
      });
      return result?.data?.data;
    },
    onError: (error) => {
      console.log("error when fetching user", error);
    },
    initialData: userLocalStore.getUser(),
    retryOnMount: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (!user) {
      userLocalStore.removeUser();
    } else {
      userLocalStore.saveUser(user);
    }
  }, [user]);
  return {
    user: decryptUser(user) ?? null,
  };
}

export default useUser;
