import React, { useEffect, useReducer, useState } from "react";
import Layout from "../../commons/Layout";
import SubCategoryHero from "./SubCategoryHero";
import ContentBodySection from "./ContentBodySection";
import TypeSection from "./TypeSection";
import { useParams } from "react-router-dom";
import useProductSubcategory from "../../hooks/useProductSubcategory";
import { filterReducer } from "../../reducers/productFilterReducer";
import useDebounce from "../../hooks/useDebounce";
import useColors from "../../hooks/useColors";
import useVendors from "../../hooks/useVendors";
import useTypesBySubcategory from "../../hooks/useTypesBySubcategory";
import { NEW_TO_OLD } from "../../constants/texts";
import { FaFilter } from "react-icons/fa";

function SubCategory() {
  const params = useParams();
  const { slug } = params;
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const initialState = {
    colorFiltersArray: [],
    brandFiltersArray: [],
    typeFiltersArray: [],
    priceFiltersArray: [0, 100],
    productSortFilter: NEW_TO_OLD,
  };

  const [state, dispatch] = useReducer(filterReducer, initialState);
  const deboundedState = useDebounce(state, 1000);

  const { productSubcategory, isLoading } = useProductSubcategory(
    slug,
    pageSize,
    pageNumber,
    deboundedState.colorFiltersArray,
    deboundedState.brandFiltersArray,
    deboundedState.typeFiltersArray,
    deboundedState.priceFiltersArray,
    deboundedState.productSortFilter
  );

  const { colors } = useColors();
  const { vendors } = useVendors();
  const { types } = useTypesBySubcategory(slug);

  useEffect(() => {
    setPageNumber(1);
  }, [state, pageSize, slug]);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, [pageSize, pageNumber]);

  return (
    <Layout>
      <div>
        <SubCategoryHero name={productSubcategory?.record?.name} />
        <TypeSection
          dispatch={dispatch}
          types={types ?? []}
          isLoading={isLoading}
          slug={slug}
          filters={state}
        />
        <div className="hidden max-sm:flex justify-end w-[95%] mx-auto mt-8">
          <div
            onClick={() => setIsFilterModalOpen((prev) => !prev)}
            className="flex items-center gap-2 w-fit border border-gray-400 rounded-md px-4 py-1 text-[14px] max-sm:text-[12px]"
          >
            <FaFilter />
            <p>Filters</p>
          </div>
        </div>
        <ContentBodySection
          id={productSubcategory?.record?.id}
          isLoading={isLoading}
          subcategory={productSubcategory?.record}
          category={productSubcategory?.record?.Category}
          totalRecord={productSubcategory?.totalRecords ?? 0}
          pageSize={pageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          dispatch={dispatch}
          filters={state}
          brandList={vendors?.vendors ?? []}
          colorList={colors ?? []}
          typeList={types ?? []}
          priceMax={productSubcategory?.priceMax ?? 100000}
          isFilterModalOpen={isFilterModalOpen}
          setIsFilterModalOpen={setIsFilterModalOpen}
        />
      </div>
    </Layout>
  );
}

export default SubCategory;
