import React, { useState } from "react";
import { AiOutlineMinus } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";

function QuantityAdjuster({
  quantityValue,
  setQuantityValue,
  className = "p-[12px] w-[152px] max-md:p-[6px] text-[20px] font-[600] ",
  maxValue = 10,
}) {
  const increment = () => {
    if (quantityValue < maxValue) {
      setQuantityValue(++quantityValue);
    }
  };

  const decrement = () => {
    if (quantityValue > 0) {
      setQuantityValue(--quantityValue);
    }
  };

  const setToDefault = () => {
    if (quantityValue.toString().length === 0) {
      setQuantityValue(0);
    }
  };

  const handleQuantityValueChange = (e, callback) => {
    let val = e.target.value;
    let numberOnly = "";
    for (let x of val) {
      if (/\^*[0-9]/.test(x)) {
        numberOnly += x;
      }
    }

    callback(numberOnly);
  };

  return (
    <div
      className={`${className} bg-[#EAECF0] flex gap-2 border rounded-full items-center`}
    >
      <div className="cursor-pointer hover:text-[#FF9D21]" onClick={decrement}>
        <AiOutlineMinus className="text-[12px]" />
      </div>
      <div className="">
        <input
          onBlur={setToDefault}
          className="outline-none w-full text-md text-center bg-[transparent]"
          value={quantityValue}
          onChange={(e) => handleQuantityValueChange(e, setQuantityValue)}
        />
      </div>
      <div className="cursor-pointer hover:text-[#FF9D21]" onClick={increment}>
        <AiOutlinePlus className="text-[12px]" />
      </div>
    </div>
  );
}

export default QuantityAdjuster;
