import React, { useState } from "react";
import SubcategoryMiniItemLoader from "../../commons/loaders/SubcategoryMiniItemLoader";
import SlidingArrows from "../../commons/SlidingArrows";
import SubCategoryMiniItem from "../../commons/SubCategoryMiniItem";
import { SET_FILTER_TYPES_ARRAY } from "../../reducers/productFilterReducer/types";

function TypeSection({ types = [], isLoading, filters, dispatch }) {
  const [index, setIndex] = useState(0);
  const THUMBNAIL_PER_PAGE = 5;

  const changeIndex = (step) => {
    if (step > 0) {
      increment();
    } else {
      decrement();
    }
  };

  const increment = () => {
    if (index < types?.length - THUMBNAIL_PER_PAGE) {
      setIndex((prev) => ++prev);
    }
  };

  const decrement = () => {
    if (index > 0) {
      setIndex((prev) => --prev);
    }
  };
  return (
    <div className="w-[96%] mx-auto my-4 relative overflow-x-auto">
      {isLoading && (
        <>
          <SubcategoryMiniItemLoader />
        </>
      )}
      {!isLoading && (
        <div
          className={`flex gap-4 max-md:gap-2 ${
            types?.length <= THUMBNAIL_PER_PAGE && "justify-center"
          }`}
          style={{
            width: "100%",
            display: "flex",
            transition: "all 1.5s ease",
            transform: `translateX(${index * -210}px)`,
          }}
        >
          {types?.map((type, id) => {
            return (
              <SubCategoryMiniItem
                key={id}
                selected={filters.typeFiltersArray.includes(
                  type?.id?.toString()
                )}
                itemsList={filters.typeFiltersArray}
                subCategory={type}
                imageKey={"thumbnailImagePath"}
                dispatch={dispatch}
                actionType={SET_FILTER_TYPES_ARRAY}
              />
            );
          })}
        </div>
      )}
      {types?.length > THUMBNAIL_PER_PAGE && (
        <SlidingArrows
          topPositionClass="top-[5vh]"
          widthClass="w-full"
          leftPositionClass="left-[0]"
          showLeftArrow={index > 0}
          showRightArrow={index < types?.length - THUMBNAIL_PER_PAGE}
          onLeftArrowClick={() => changeIndex(-1)}
          onRightArrowClick={() => changeIndex(+1)}
        />
      )}
    </div>
  );
}

export default TypeSection;
