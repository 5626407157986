import React from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import CarouselArrow from "./CarouselArrow";

function SlidingArrows({
  widthClass = "w-[97%]",
  topPositionClass = "top-[15vh]",
  showLeftArrow = true,
  showRightArrow = true,
  leftPositionClass = "left-5",
  onLeftArrowClick,
  onRightArrowClick,
  leftArrowClass = "absolute left-0",
  rightArrowClass = "absolute right-0",
}) {
  return (
    <div>
      <div
        className={`flex justify-between absolute ${widthClass} ${topPositionClass} ${leftPositionClass}`}
      >
        {showLeftArrow ? (
          <CarouselArrow onClick={onLeftArrowClick} className={leftArrowClass}>
            <MdKeyboardArrowLeft className="text-lg" />
          </CarouselArrow>
        ) : (
          <div></div>
        )}

        {showRightArrow ? (
          <CarouselArrow
            onClick={onRightArrowClick}
            className={rightArrowClass}
          >
            <MdKeyboardArrowRight className="text-lg" />
          </CarouselArrow>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default SlidingArrows;
