import React from "react";
import { getFileUrl } from "../helpers/extractors";

function SubCategoryItem({ subCategory }) {
  return (
    <div className="">
      <div className="rounded-sm shadow-sm w-[250px] h-[150px] max-lg:w-[200px] max-lg:h-[150px] max-md:h-[100px] max-md:w-[150px]">
        <img
          alt="subcategory"
          src={getFileUrl(subCategory.thumbnailImagePath)}
          className="rounded-sm w-full h-full object-cover"
          loading="lazy"
        />
      </div>
      <p className="font-[500] mt-2 text-[16px] max-lg:text-[14px] max-md:text-[12px]">
        {subCategory.name}
      </p>
    </div>
  );
}

export default SubCategoryItem;
