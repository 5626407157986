import { Zoom } from "react-reveal";
import { getFileUrl } from "./../helpers/extractors";

function BrandThumbnailItem({ brand }) {
  return (
    <Zoom>
      <div className="w-[200px] h-[100px] max-md:w-[150px]">
        <img
          className="object-contain w-full h-full"
          src={getFileUrl(brand.imagePath)}
          alt={`wudify_with_brand_${brand.name}`}
        />
      </div>
    </Zoom>
  );
}

export default BrandThumbnailItem;
