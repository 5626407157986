import React from "react";
import { getFileUrl } from "./../helpers/extractors";
import { Fade } from "react-reveal";

function PhotoDescriptionItem({
  title,
  description,
  imagePath,
  reversed,
  link,
  children,
}) {
  return (
    <div
      className={`flex ${
        reversed ? "flex-row-reverse" : ""
      } gap-[7em] items-center max-lg:gap-[5em] max-md:gap-[3em] max-sm:flex-col`}
    >
      <Fade bottom>
        <div className="w-[50%] max-sm:w-full text-left">
          <div className="">
            <h2
              className="text-[42px] max-lg:text-[36px] max-md:text-[28px] font-[600]"
              style={{ lineHeight: "50.4px" }}
            >
              {title}
            </h2>
            <p className="text-[16px] max-md:text-[14px] font-[500] text-[#667185] my-5">
              {description}
            </p>
            {children}
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className="w-[50%] h-[420px] max-md:h-[280px] max-sm:w-full shadow-md">
          <img
            src={getFileUrl(imagePath)}
            alt=""
            className="h-full w-full"
            style={{ objectFit: "cover" }}
          />
        </div>
      </Fade>
    </div>
  );
}

export default PhotoDescriptionItem;
