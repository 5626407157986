import React, { useState, useEffect, useCallback } from "react";
import { IoTrashBinOutline } from "react-icons/io5";
import QuantityAdjuster from "../../commons/QuantityAdjuster";
import ViewAllButton from "../../commons/ViewAllButton";
import useUpdateCartItem from "../../hooks/useUpdateCartItem";
import useUser from "../../hooks/useUser";
import { debounce } from "lodash";
import useDeleteCartItem from "../../hooks/useDeleteCartItem";
import CartItemLoader from "../../commons/loaders/CartItemLoader";
import { getColorArray, getFileUrl } from "../../helpers/extractors";
import ColorCircle from "../../commons/ColorCircle";
import useAddToWishlist from "../../hooks/useAddToWishlist";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import AddToWishlistButton from "../../commons/AddToWishlistButton";
import useUpdateBuyNowItem from "../../hooks/useUpdateBuyNowItem";
import CustomPriceDisplay from "../../commons/CustomPriceDisplay";

function BuyNowItem({
  item,
  setIsError,
  setSnackBarMessage,
  setOpenSnackBar,
  setCartUpdated,
}) {
  const [quantity, setQuantity] = useState(1);
  const [selectedColor, setColor] = useState("");
  const { user } = useUser();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { updateBuyNowItem, isLoading } = useUpdateBuyNowItem();
  const { addToUserWishlist } = useAddToWishlist();
  const { deleteCartItem } = useDeleteCartItem();

  const handleSetQuantity = (value, color) => {
    setQuantity(value);
    updateBuyNowDetails(value, color);
  };

  const handleChangeColor = (quantity, color) => {
    setColor(color);
    updateBuyNowDetails(quantity, color);
  };

  const updateBuyNowDetails = (quantity, color) => {
    updateBuyNowItem({
      userId: user?.id,
      accessToken: user?.accessToken,
      quantity,
      productId: item?.Product.id,
      id: item?.id,
      color,
    })
      .then(() => {})
      .catch((err) => {})
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const debounced = useCallback(debounce(handleSetQuantity, 500), []);
  const debouncedColor = useCallback(debounce(handleChangeColor, 500), []);

  useEffect(() => {
    setQuantity(item?.quantity);
    setColor(item?.color);
  }, [item]);
  return (
    <>
      {!isDeleting && (
        <>
          <div className="flex gap-6 max-sm:flex-col justify-between">
            <div className="flex max-sm:w-full gap-3 w-[70%]">
              <div className="h-[120px] w-[120px]">
                <img
                  className="w-full h-full rounded-lg"
                  style={{ objectFit: "cover" }}
                  src={item?.Product?.imageOne}
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="flex flex-col justify-around">
                <p className="font-[600] text-[18px]">{item?.Product?.name}</p>
                <p className="font-[500] text-[14px]">Installation fee</p>
                <p className="font-[400] text-[14px] text-[#475367]">
                  {item?.Product?.colors && (
                    <>
                      <span>Color : </span>
                      <span
                        className="font-[500]"
                        style={{ color: selectedColor }}
                      >
                        {selectedColor?.toUpperCase()}
                      </span>
                    </>
                  )}
                </p>
                <p className="font-[400] text-[14px] text-[#475367]">
                  {item?.Product?.colors && (
                    <>
                      <div className="flex gap-1">
                        {getColorArray(item?.Product?.colors)?.map((color) => {
                          return (
                            <ColorCircle
                              style={{ backgroundColor: color }}
                              value={color}
                              colorHex={color}
                              selectedColor={selectedColor}
                              getColor={(e) => debouncedColor(quantity, color)}
                            >
                              {color}
                            </ColorCircle>
                          );
                        })}
                      </div>
                    </>
                  )}
                </p>
              </div>
            </div>

            <div className="flex max-sm:w-full  w-[60%]">
              <div className="flex flex-col justify-around text-right pr-8">
                <p className="font-[600] text-[18px] max-lg:text-[16px] max-md:text-[14px] flex gap-1 justify-end mb-1">
                  Amount:{" "}
                  <CustomPriceDisplay
                    title="Sub Total"
                    price={`N${item?.Product?.priceAfterDiscount ?? 0}`}
                  />
                </p>
                <p className="font-[500] text-[14px] max-md:text-[12px] flex justify-end gap-1 mb-1">
                  Amount:{" "}
                  <CustomPriceDisplay
                    title="Sub Total"
                    price={`N${item?.Product?.installationPrice ?? 0}`}
                  />
                </p>
                <div className="flex gap-3 justify-end">
                  <QuantityAdjuster
                    setQuantityValue={(e) => debounced(e, selectedColor)}
                    quantityValue={quantity}
                    maxValue={item?.Product?.quantity}
                    className={`font-[500] text-[14px] max-md:text-[12px] w-1/3 py-1 px-2`}
                  />
                  <AddToWishlistButton
                    color={selectedColor}
                    productId={item?.Product.id}
                    quantity={quantity}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isDeleting && <CartItemLoader />}
    </>
  );
}

export default BuyNowItem;
