import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { getFileUrl } from "./../helpers/extractors";

function ProductSearchItem({ product }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex items-center border bg-gray-100 gap-4">
        <img
          src={product.imageOne}
          className="h-[4.5em] w-[4.5em] border cursor-pointer"
          alt=""
          onClick={() => navigate(`/products/${product.slug}`)}
        />
        <div className="flex flex-col gap-1">
          <a href={`/products/${product.slug}`}>
            <p className="font-[500] text-[14px] text-left">{product.name}</p>
          </a>
          <div className="flex text-[10px] gap-2 items-center">
            <p className="font-[500] text-gray-900">
              <a href={`/types/${product.Type.slug}`}>{product.Type.name}</a>
            </p>
            <IoIosArrowForward />
            <p className="font-[500] text-blue-500">
              <a href={`/sub-categories/${product.Type.Subcategory.slug}`}>
                {product.Type.Subcategory.name}
              </a>
            </p>
            <IoIosArrowForward />
            <p className="font-[500] text-orange-400">
              <a href={`/categories/${product.Type.Subcategory.Category.slug}`}>
                {product.Type.Subcategory.Category.name}
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductSearchItem;
