import axiosInstance from "../utils/axiosInstance";

export const getVendors = async (data) => {
  return axiosInstance(
    `/vendors?status=${data?.status}&searchTerm=${data?.searchTerm}&dateFrom=&dateTo=`,
    "GET",
    data
  ).then((response) => {
    return response;
  });
};
