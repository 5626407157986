import { Zoom } from "react-reveal";

function BlogItem({ blog, reversed, link }) {
  return (
    <div
      className={`flex ${
        reversed ? "flex-row-reverse" : ""
      } items-center gap-[7em] max-lg:gap-[4em] max-md:gap-[2em] max-sm:flex-col`}
    >
      <Zoom>
        <div className="w-[50%] max-sm:w-full text-left">
          <div className="">
            <h2
              className="text-[42px] max-md:text-[24px] max-lg:text-[34px] font-[600]"
              // style={{ lineHeight: "50.4px" }}
            >
              {blog?.title}
            </h2>
            <p className="text-[15px] max-md:text-[12px] font-[500] text-[#828282] my-5">
              {blog?.shortDescription}
            </p>
            <button className="bg-black text-white rounded-md py-3 px-6 font-semibold hover:opacity-80">
              <a href={link}>{"Read More"}</a>
            </button>
          </div>
        </div>
      </Zoom>
      <div className="w-1/2 max-sm:w-full h-[350px] max-sm:h-[250px]">
        <img
          src={blog?.subImagePath}
          alt=""
          className="h-full w-full"
          style={{ objectFit: "cover" }}
        />
      </div>
    </div>
  );
}

export default BlogItem;
