import React, { useEffect, useState } from "react";
import AddedToCartButton from "../../commons/AddedToCartButton";
import AddToCartButton from "../../commons/AddToCartButton";
import AddToWishlistButton from "../../commons/AddToWishlistButton";
import BuyNowButton from "../../commons/BuyNowButton";
import ColorCircle from "../../commons/ColorCircle";
import ProductDetailsSectionLoader from "../../commons/loaders/ProductDetailsSectionLoader";
import QuantityAdjuster from "../../commons/QuantityAdjuster";
import SocialLinks from "../../commons/SocialLinks";
import { getColorArray } from "../../helpers/extractors";
import { alreadyAdded } from "../../helpers/products";
import ProductThumbnailImage from "./ProductThumbnailImage";
import CustomPriceDisplay from "../../commons/CustomPriceDisplay";
import CustomDiscountDisplay from "../../commons/CustomDiscountDisplay";
import CustomOutOfStockDisplay from "../../commons/CustomOutOfStockDisplay";
import { getFileUrl } from "./../../helpers/extractors";

function ProductDetailsSection({ product, isLoading }) {
  const [quantityValue, setQuantityValue] = useState(1);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [allImages, setAllImages] = useState([]);

  const [index, setIndex] = useState(0);
  const THUMBNAIL_PER_PAGE = 6;

  const changeIndex = (step) => {
    console.log("index", index);
    console.log("step", step);
    if (step > 0) {
      increment();
    } else {
      decrement();
    }
  };

  const increment = () => {
    if (index < allImages.length - THUMBNAIL_PER_PAGE) {
      setIndex((prev) => ++prev);
    }
  };

  const decrement = () => {
    if (index > 0) {
      setIndex((prev) => --prev);
    }
  };

  const getSelectedColor = (color) => {
    setSelectedColor(color);
  };

  useEffect(() => {
    setSelectedImage(product?.imageOne);
    setSelectedColor(getColorArray(product?.colors)[0]);
    setAllImages([
      product?.imageOne,
      product?.imageTwo,
      product?.imageThree,
      product?.imageFour,
      product?.imageFive,
      product?.imageSix,
    ]);
  }, [product]);

  return (
    <>
      {!isLoading && (
        <div className="flex max-md:flex-col gap-10 max-md:gap-6 items-start justify-between">
          {/* Left section */}
          <div className="w-1/2 max-md:w-full">
            {/* Main image */}
            <div className="h-[457px] max-md:h-[280px]">
              <img
                className="h-full w-full object-cover"
                src={selectedImage}
                alt=""
                // style={{ objectFit: "cover" }}
                loading="lazy"
              />
            </div>

            {/* Other images */}
            <div className="relative w-full">
              <div
                className="gap-[20px] max-md:gap-[10px] flex items-center justify-start mt-[20px]"
                style={{}}
              >
                {allImages
                  .filter((_) => _ != null && _ != "undefined" && _ != "")
                  .map((image, idx) => {
                    return (
                      <ProductThumbnailImage
                        key={idx}
                        imagePath={image}
                        onSelect={setSelectedImage}
                        selected={selectedImage === image}
                      />
                    );
                  })}
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="w-1/2 max-md:w-full text-left mt-0 pt-0">
            <div>
              <h3 className="text-[28px] max-lg:text-[22px] max-md:text-[15px] font-[600] mb-3">
                {product?.name}
              </h3>

              {/* Description */}
              <p className="text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[500] mb-4 text-[#667185]">
                {product?.shortDescription}
              </p>

              {/* SkU and Brand */}
              <div className="flex gap-3 text-[14px] max-md:text-[12px] font-[500] mb-3 text-[#667185]">
                <p>SKU: {product?.sku}</p>
                <p>
                  BRAND:{" "}
                  <span className="text-[#FF9D21]">
                    {product?.Vendor?.businessName?.toUpperCase()}
                  </span>
                </p>
              </div>

              {/* Price */}
              <div className="text-[28px] max-lg:text-[22px] max-md:text-[15px] font-[600] gap-3 mb-4 flex items-center">
                <CustomPriceDisplay price={product?.priceAfterDiscount} />
                {product?.discountPrice && (
                  <div
                    style={{
                      textDecoration: "line-through",
                      opacity: "0.6",
                      fontSize: "16px",
                    }}
                  >
                    <CustomPriceDisplay price={product?.price} />
                  </div>
                )}
                <CustomDiscountDisplay
                  discountPrice={product?.discountPrice}
                  className="mb-2.5"
                />

                <CustomOutOfStockDisplay
                  quantity={product?.quantity}
                  className=""
                />
              </div>

              {/* Color */}
              <div className="text-[14px] max-md:text-[12px] font-[600] mb-6">
                {selectedColor}
                <p className="mb-3">Choose a Color </p>
                <div className="flex gap-1">
                  {getColorArray(product?.colors).map((color, idx) => {
                    return (
                      <ColorCircle
                        key={idx}
                        colorHex={color}
                        getColor={getSelectedColor}
                        selectedColor={selectedColor}
                      />
                    );
                  })}
                </div>
              </div>

              {/* Quantity */}
              <div className="text-[14px] max-md:text-[12px] font-[600] mb-6">
                <p className="mb-3">Quantity</p>
                <QuantityAdjuster
                  // className="!w-fit"
                  quantityValue={quantityValue}
                  setQuantityValue={setQuantityValue}
                  maxValue={product?.quantity}
                />
              </div>

              {/* Installation Request */}
              {product?.needsInstallation && (
                <div className="flex gap-1 items-start">
                  <div className="mt-3">
                    <input type="checkbox" />
                  </div>
                  <div>
                    <div className="flex mb-3 items-center">
                      <div className="text-[18px] max-lg:text-[16px] max-md:text-[12px] font-[500] mr-3">
                        Add Installation
                      </div>
                      <div className="bg-[#FF9D21] text-white rounded-lg py-1.5 px-3 mr-4">
                        Recommended
                      </div>
                      <div className="text-[24px] max-lg:text-[20px] max-md:text-[16px] font-[600] ml-10">
                        <CustomPriceDisplay
                          price={product?.installationPrice}
                        />
                      </div>
                    </div>
                    <div className="text-[14px] max-md:text-[12px] font-[400]">
                      Save time during installation when you add this service to
                      your cart.
                    </div>
                  </div>
                </div>
              )}

              {/* Cart and Favourite */}
              <div className="flex items-center justify-start gap-5 mt-5">
                {product?.quantity < 1 ? (
                  <CustomOutOfStockDisplay quantity={product?.quantity} />
                ) : (
                  <div className="flex items-center justify-start gap-5">
                    <BuyNowButton
                      slug={product?.slug}
                      productId={product?.id}
                      quantity={quantityValue}
                      chooseColor={product?.colors}
                      color={selectedColor}
                    />
                    <div>
                      {alreadyAdded(product?.Carts, product?.id) ? (
                        <AddedToCartButton />
                      ) : (
                        <AddToCartButton
                          color={selectedColor}
                          quantity={quantityValue}
                          productId={product?.id}
                          chooseColor={product?.colors}
                        />
                      )}
                    </div>
                  </div>
                )}
                <AddToWishlistButton
                  slug={product?.slug}
                  productId={product?.id}
                  quantity={quantityValue}
                  chooseColor={product?.colors}
                  color={selectedColor}
                  useIcon={true}
                  product={product}
                  isAdded={alreadyAdded(product?.Wishlists, product?.id)}
                />
              </div>

              {/* Social Links Sharing */}
              <div className="text-[14px] font-[600] mb-6 mt-5">
                <p className="mb-3">Share Product</p>
                <div className="flex gap-1">
                  <SocialLinks />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isLoading && <ProductDetailsSectionLoader />}
    </>
  );
}

export default ProductDetailsSection;
