import React, { useState } from "react";
import Layout from "../../commons/Layout";
import PageTopDescription from "../../commons/PageTopDescription";
import QuestionAndAnswer from "../../commons/QuestionAndAnswer";
import SearchInput from "../../commons/SearchInput";
import { useQuery } from "@tanstack/react-query";
import { getFaqs } from "../../services/faqs-service";
import QuestionAndAnswerLoaders from "../../commons/loaders/QuestionAndAnswerLoaders";
import useDebounce from "../../hooks/useDebounce";

function Faq() {
  let faqs_data = [];

  const [searchValue, setSearchValue] = useState("");
  const debouncedSearch = useDebounce(searchValue, 500);

  // Queries
  const faqs = useQuery({
    queryKey: ["faqs", debouncedSearch],
    queryFn: async () => {
      const result = await getFaqs(debouncedSearch);
      if (result.data.error) {
      }
      return result;
    },
    onError: (error) => {
      console.log("error when fetching faqs", error);
    },
  });

  faqs_data = faqs?.data?.data?.data;

  return (
    <Layout>
      <div className="w-[70%] max-lg:w-[80%] max-md:w-[90%] mx-auto py-12 max-md:py-6">
        <PageTopDescription
          // heading={"FAQ"}
          subHeading="Ask Us Anything"
          description="Need something cleared up? Here are our most frequently asked questions."
        />

        <div className="w-[35%] max-lg:w-1/2 max-md:w-4/5 mx-auto mt-3 mb-8">
          <SearchInput
            onChange={(e) => {
              console.log(e);
              setSearchValue(e);
            }}
            value={searchValue}
            placeholder={"Search"}
            className=""
          />
        </div>
        <div className="font-[600] text-[40px] max-lg:text-[30px] max-md:text-[20px] mb-8">
          Frequently Asked Questions
        </div>

        {faqs.isLoading && <QuestionAndAnswerLoaders />}

        {faqs_data
          ?.filter((faq) => !faq?.category)
          ?.map((faq, index) => (
            <div key={index}>
              <QuestionAndAnswer
                number={index + 1}
                question={faq.question}
                answer={faq.answer}
              />
            </div>
          ))}
        {faqs_data?.filter((faq) => faq?.category === "product")?.length >
          0 && (
          <div>
            <p className="text-left text-[24px] max-lg:text-[20px] max-md:text-[18px] font-[600] mb-4 text-[#18181B] my-8">
              Product Information
            </p>
            {faqs_data
              ?.filter((faq) => faq?.category === "product")
              ?.map((faq, index) => (
                <div key={index}>
                  <QuestionAndAnswer
                    number={index + 1}
                    question={faq.question}
                    answer={faq.answer}
                  />
                </div>
              ))}
          </div>
        )}
        {faqs_data?.filter((faq) => faq?.category === "shipping")?.length >
          0 && (
          <div>
            <p className="text-left text-[24px] max-lg:text-[20px] max-md:text-[18px] font-[600] mb-4 text-[#18181B] my-8">
              Shipping & Delivery
            </p>
            {faqs_data
              ?.filter((faq) => faq?.category === "shipping")
              ?.map((faq, index) => (
                <div key={index}>
                  <QuestionAndAnswer
                    number={index + 1}
                    question={faq.question}
                    answer={faq.answer}
                    answerTwo={faq.answerTwo ?? ""}
                    answerThree={faq.answerThree ?? ""}
                    answerFour={faq.answerFour ?? ""}
                    answerFive={faq.answerFive ?? ""}
                  />
                </div>
              ))}
          </div>
        )}
        {faqs_data?.filter((faq) => faq?.category === "Returns")?.length >
          0 && (
          <div>
            <p className="text-left text-[24px] max-lg:text-[20px] max-md:text-[18px] font-[600] mb-4 text-[#18181B] my-8">
              Returns & Refunds
            </p>
            {faqs_data
              ?.filter((faq) => faq?.category === "Returns")
              ?.map((faq, index) => (
                <div key={index}>
                  <QuestionAndAnswer
                    number={index + 1}
                    question={faq.question}
                    answer={faq.answer}
                    answerTwo={faq.answerTwo ?? ""}
                    answerThree={faq.answerThree ?? ""}
                    answerFour={faq.answerFour ?? ""}
                    answerFive={faq.answerFive ?? ""}
                  />
                </div>
              ))}
          </div>
        )}
        {faqs_data?.filter((faq) => faq?.category === "bespoke")?.length >
          0 && (
          <div>
            <p className="text-left text-[24px] max-lg:text-[20px] max-md:text-[18px] font-[600] mb-4 text-[#18181B] my-8">
              Bespoke Services
            </p>
            {faqs_data
              ?.filter((faq) => faq?.category === "bespoke")
              ?.map((faq, index) => (
                <div key={index}>
                  <QuestionAndAnswer
                    number={index + 1}
                    question={faq.question}
                    answer={faq.answer}
                    answerTwo={faq.answerTwo ?? ""}
                    answerThree={faq.answerThree ?? ""}
                    answerFour={faq.answerFour ?? ""}
                    answerFive={faq.answerFive ?? ""}
                  />
                </div>
              ))}
          </div>
        )}
        {faqs_data?.filter((faq) => faq?.category === "payments")?.length >
          0 && (
          <div>
            <p className="text-left text-[24px] max-lg:text-[20px] max-md:text-[18px] font-[600] mb-4 text-[#18181B] my-8">
              Payments & Pricing
            </p>
            {faqs_data
              ?.filter((faq) => faq?.category === "payments")
              ?.map((faq, index) => (
                <div key={index}>
                  <QuestionAndAnswer
                    number={index + 1}
                    question={faq.question}
                    answer={faq.answer}
                    answerTwo={faq.answerTwo ?? ""}
                    answerThree={faq.answerThree ?? ""}
                    answerFour={faq.answerFour ?? ""}
                    answerFive={faq.answerFive ?? ""}
                  />
                </div>
              ))}
          </div>
        )}
        {faqs_data?.filter((faq) => faq?.category === "customer")?.length >
          0 && (
          <div>
            <p className="text-left text-[24px] max-lg:text-[20px] max-md:text-[18px] font-[600] mb-4 text-[#18181B] my-8">
              Customer Support
            </p>
            {faqs_data
              ?.filter((faq) => faq?.category === "customer")
              ?.map((faq, index) => (
                <div key={index}>
                  <QuestionAndAnswer
                    number={index + 1}
                    question={faq.question}
                    answer={faq.answer}
                    answerTwo={faq.answerTwo ?? ""}
                    answerThree={faq.answerThree ?? ""}
                    answerFour={faq.answerFour ?? ""}
                    answerFive={faq.answerFive ?? ""}
                  />
                </div>
              ))}
          </div>
        )}
        {faqs_data?.filter((faq) => faq?.category === "order")?.length > 0 && (
          <div>
            <p className="text-left text-[24px] max-lg:text-[20px] max-md:text-[18px] font-[600] mb-4 text-[#18181B] my-8">
              Orders
            </p>
            {faqs_data
              ?.filter((faq) => faq?.category === "order")
              ?.map((faq, index) => (
                <div key={index}>
                  <QuestionAndAnswer
                    number={index + 1}
                    question={faq.question}
                    answer={faq.answer}
                    answerTwo={faq.answerTwo ?? ""}
                    answerThree={faq.answerThree ?? ""}
                    answerFour={faq.answerFour ?? ""}
                    answerFive={faq.answerFive ?? ""}
                  />
                </div>
              ))}
          </div>
        )}
        {faqs_data?.filter((faq) => faq?.category === "vendor")?.length > 0 && (
          <div>
            <p className="text-left text-[24px] max-lg:text-[20px] max-md:text-[18px] font-[600] mb-4 text-[#18181B] my-8">
              Vendor Information
            </p>
            {faqs_data
              ?.filter((faq) => faq?.category === "vendor")
              ?.map((faq, index) => (
                <div key={index}>
                  <QuestionAndAnswer
                    number={index + 1}
                    question={faq.question}
                    answer={faq.answer}
                    answerTwo={faq.answerTwo ?? ""}
                    answerThree={faq.answerThree ?? ""}
                    answerFour={faq.answerFour ?? ""}
                    answerFive={faq.answerFive ?? ""}
                  />
                </div>
              ))}
          </div>
        )}
      </div>
    </Layout>
  );
}

export default Faq;
