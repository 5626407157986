import CustomDiscountDisplay from "./CustomDiscountDisplay";
import CustomOutOfStockDisplay from "./CustomOutOfStockDisplay";
import CustomPriceDisplay from "./CustomPriceDisplay";
import { Fade } from "react-reveal";

function ProductItemMini({ product }) {
  return (
    <Fade bottom>
      <a className="my-4 max-md:my-2" href={`/products/${product?.slug}`}>
        <div className="cursor-pointer hover:opacity-70 relative shadow-lg rounded-b-md">
          <div
            className="h-[319px] max-lg:h-[240px] max-md:h-[160px] max-sm:h-[120px]"
            style={{ overflow: "hidden" }}
          >
            <img
              src={product?.imageOne}
              className="h-full w-full rounded-t-md object-cover"
              alt=""
              loading="lazy"
            />
          </div>
          <div className="absolute top-0 max-sm:-top-[5px]">
            <CustomDiscountDisplay discountPrice={product?.discountPrice} />
          </div>
          <div className="absolute -top-[10px] right-0">
            <CustomOutOfStockDisplay quantity={product?.quantity} />
          </div>
          <div className="px-2 py-2">
            <div className="flex flex-wrap gap-1 items-center justify-between font-[500] text-[16px] max-lg:text-[14px] max-md:text-[12px]">
              <div>{product?.name}</div>
              <CustomPriceDisplay price={product?.priceAfterDiscount} />
            </div>
            <div className="flex flex-wrap gap-1 justify-between mt-1 font-[500] text-[12px]">
              <div className="flex gap-1 items-center w-fit overflow-x-auto">
                <p className="max-sm:text-[10px]">Colors:</p>
                <div className="flex gap-1 items-center">
                  {product?.colors?.split(",")?.map((color, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`w-[10px] h-[10px] rounded-full border-[0.5px] border-[#888888]`}
                        style={{ backgroundColor: color?.toLowerCase() }}
                      />
                    );
                  })}
                </div>
              </div>
              <div
                className="text-gray-500"
                style={{ textDecoration: "line-through", opacity: "0.6" }}
              >
                {product?.discountPrice ? (
                  <CustomPriceDisplay price={product?.price} />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="mt-1 flex max-sm:flex-col max-sm:items-start gap-1 justify-between items-center">
              <a className="" href={`/product-types/${product?.Type?.slug}`}>
                <div className="font-[500] text-left">
                  Type: {product?.Type?.name}
                </div>
              </a>
              <div className="">Brand: {product?.Vendor?.businessName}</div>
            </div>
          </div>
        </div>
      </a>
    </Fade>
  );
}

export default ProductItemMini;
