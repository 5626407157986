import React from "react";
import CardTitleBig from "../../commons/CardTitleBig";
import Layout from "../../commons/Layout";
import PhotoDescriptionItem from "../../commons/PhotoDescriptionItem";
import BrandSection from "../home/BrandSection";
import { Fade } from "react-reveal";

function AboutUs() {
  return (
    <Layout>
      <div className="w-[90%] max-md:w-full mx-auto px-5 py-10">
        <Fade bottom>
          <div className="text-[60px] max-lg:text-[50px] max-md:text-[40px] max-sm:text-[32px] font-[600] mb-5">
            About Wudify Africa
          </div>
        </Fade>
        <Fade bottom>
          <div className="font-[500] text-[16px] max-md:text-[14px] mx-auto text-[#667185] text-center max-md:text-left">
            Wudify Africa is a leading e-commerce platform revolutionizing the
            digital supply chain for furniture, lighting, bedding, and home
            accessories. Our mission is to make high-quality home essentials
            easily accessible to every urban resident. By connecting customers
            directly with trusted businesses, we ensure prompt, seamless, and
            reliable doorstep delivery. Dedicated to enhancing lifestyles and
            enriching homes, Wudify empowers individuals to effortlessly create
            living spaces that reflect comfort, style, and functionality.
          </div>
        </Fade>
        <div className="my-28 max-md:my-12">
          <PhotoDescriptionItem
            title="Our Story"
            reversed={true}
            imagePath={"/assets/about-us-img.jpeg"}
            description={
              "At Wudify Africa, we believe that creating a beautiful, functional living space shouldn’t be complicated or out of reach. Our journey began with a simple idea: to bridge the gap between quality home essentials and the urban residents who need them."
            }
          >
            <p className="text-[16px] max-md:text-[14px] font-[500] text-[#667185] my-2">
              We recognized the challenges many people face, endless store
              visits, unreliable supply chains, and delayed deliveries, when
              trying to furnish their homes or upgrade their spaces. Driven by
              the desire to simplify this process, Wudify was born.
            </p>
            <p className="text-[16px] max-md:text-[14px] font-[500] text-[#667185] my-2">
              Through our e-commerce-driven platform, we connect customers
              directly to trusted businesses, ensuring seamless access to
              premium furniture, lighting, bedding, and home accessories. With a
              focus on efficiency, reliability, and style, we aim to transform
              the way people shop for their homes.
            </p>
            <p className="text-[16px] max-md:text-[14px] font-[500] text-[#667185] my-2">
              Welcome to Wudify - Your style, Your Story
            </p>
          </PhotoDescriptionItem>
        </div>

        <div className="my-28 max-md:my-12">
          <CardTitleBig
            title="Learn About Our Luxury brands"
            className="font-[600] text-[40px] max-lg:text-[32px] max-md:text-[24px]"
            marginBottomClass="mb-1"
          />
          <BrandSection />
        </div>

        <div className="my-28 max-md:my-12">
          <PhotoDescriptionItem
            title="Custom Design"
            imagePath={"/assets/about_custom_design.png"}
            reversed={false}
            description={
              "If you're looking for fine furniture that you can customise to your own design, look no further than Wudify. Their impressive range of bespoke furniture pieces and design options make them an ideal choice for anyone seeking to make their home feel special and unique."
            }
          >
            <div className="flex gap-5">
              <button className="bg-orange-400 text-white rounded-md py-3 px-6 font-semibold hover:opacity-80">
                <a href={"/bespoke-services"}>{"Start Now"}</a>
              </button>
              <button className="bg-orange-400 text-white rounded-md py-3 px-6 font-semibold hover:opacity-80">
                <a href={"/book-consultation"}>{"Enquiry"}</a>
              </button>
            </div>
          </PhotoDescriptionItem>
        </div>
      </div>
    </Layout>
  );
}

export default AboutUs;
