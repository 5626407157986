import React, { useState } from "react";
import { PiCaretDownBold } from "react-icons/pi";
import { useParams } from "react-router-dom";
import HeadingTitle from "../../commons/HeadingTitle";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import ReviewItem from "../../commons/ReviewItem";
import ViewAllButton from "../../commons/ViewAllButton";
import { IoIosArrowRoundForward } from "react-icons/io";

import ProductDetailsSection from "./ProductDetailsSection";
import ProductReviewAndDescriptionToggleTab from "./ProductReviewAndDescriptionToggleTab";
import RelatedProductsSection from "./RelatedProductsSection";
import useProduct from "../../hooks/useProduct";
import useUser from "../../hooks/useUser";
import ProductItemMiniLoaders from "../../commons/loaders/ProductItemMiniLoaders";
import CustomNoRecordFound from "../../commons/CustomNoRecordFound";
import NotFoundSVG from "../../commons/NotFoundSVG";

const REVIEWS_PER_PAGE = 5;
const MAX_REVIEWS_PER_PAGE = 100;
const RELATED_PRODUCTS_PER_PAGE = 4;
const MAX_RELATED_PRODUCTS_PER_PAGE = 1000;

function Product() {
  const params = useParams();
  const { slug } = params;

  const { user } = useUser();
  const { product: fetchedProduct, isLoading } = useProduct(user, slug);

  const TAB_TEXTS = fetchedProduct?.fullDescription
    ? ["Product Description", "Reviews"]
    : ["Reviews"];

  const [selectedTab, setSelectedTab] = useState(TAB_TEXTS[0]);

  const [reviewsPerPage, setReviewsPerPage] = useState(REVIEWS_PER_PAGE);
  const [relatedProductsPerPage, setRelatedProductsPerPage] = useState(
    RELATED_PRODUCTS_PER_PAGE
  );

  return (
    <Layout>
      <div className="w-[85%] max-md:w-[95%] mx-auto mt-8">
        <PageBreadCrumb
          mainText={"Home"}
          subText={fetchedProduct?.Type?.SubCategory?.Category?.name}
          otherText={fetchedProduct?.name}
          isActiveIndex={2}
        />
      </div>
      <div className="w-[85%] max-md:w-[95%] mx-auto mt-8">
        <ProductDetailsSection product={fetchedProduct} isLoading={isLoading} />
      </div>

      {/* Product Description, Review and Related Product */}
      <div className="w-[85%] max-md:w-[95%] mx-auto mb-8">
        <ProductReviewAndDescriptionToggleTab
          leftText={TAB_TEXTS[0]}
          rightText={TAB_TEXTS[1]}
          setSelected={setSelectedTab}
          selected={selectedTab}
        />
        <hr />

        {/* Tab content */}

        {fetchedProduct?.fullDescription ? (
          <div className="text-left mt-6 text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[500] text-[#667185]">
            {selectedTab === TAB_TEXTS[0] && (
              <div>{fetchedProduct?.fullDescription}</div>
            )}
            {selectedTab === TAB_TEXTS[1] && (
              <div className="text-black">
                {fetchedProduct?.Reviews?.length > 0 ? (
                  fetchedProduct?.Reviews?.filter(
                    (x, index) => index < reviewsPerPage
                  ).map((review, index) => {
                    return <ReviewItem key={index} review={review} />;
                  })
                ) : (
                  <div>This product does not have a review yet</div>
                )}
                {fetchedProduct?.Reviews?.length > reviewsPerPage && (
                  <div className="flex">
                    <div className="w-[8%]"></div>
                    <div
                      className="flex gap-1 items-center w-[90%] cursor-pointer"
                      onClick={() => setReviewsPerPage(MAX_REVIEWS_PER_PAGE)}
                    >
                      <p
                        className="text-underline font-[600] text-[18px]"
                        style={{ textDecoration: "underline" }}
                      >
                        See more reviews
                      </p>
                      <PiCaretDownBold className="text-lg font-bold" />
                    </div>
                  </div>
                )}
                {reviewsPerPage === MAX_REVIEWS_PER_PAGE && (
                  <div className="flex">
                    <div className="w-[8%]"></div>
                    <div
                      className="flex gap-1 items-center w-[90%] cursor-pointer"
                      onClick={() => setReviewsPerPage(REVIEWS_PER_PAGE)}
                    >
                      <p
                        className="text-underline font-[600] text-[18px]"
                        style={{ textDecoration: "underline" }}
                      >
                        See less reviews
                      </p>
                      <PiCaretDownBold className="text-lg font-bold" />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="text-left mt-6 text-[16px] max-lg:text-[14px] max-md:text-[12px] font-[500] text-[#667185]">
            {selectedTab === TAB_TEXTS[0] && (
              <div className="text-black">
                {fetchedProduct?.Reviews?.length > 0 ? (
                  fetchedProduct?.Reviews?.filter(
                    (x, index) => index < reviewsPerPage
                  ).map((review, index) => {
                    return <ReviewItem key={index} review={review} />;
                  })
                ) : (
                  <div>This product does not have a review yet</div>
                )}
                {fetchedProduct?.Reviews?.length > reviewsPerPage && (
                  <div className="flex">
                    <div className="w-[8%]"></div>
                    <div
                      className="flex gap-1 items-center w-[90%] cursor-pointer"
                      onClick={() => setReviewsPerPage(MAX_REVIEWS_PER_PAGE)}
                    >
                      <p
                        className="text-underline font-[600] text-[18px]"
                        style={{ textDecoration: "underline" }}
                      >
                        See more reviews
                      </p>
                      <PiCaretDownBold className="text-lg font-bold" />
                    </div>
                  </div>
                )}
                {reviewsPerPage === MAX_REVIEWS_PER_PAGE && (
                  <div className="flex">
                    <div className="w-[8%]"></div>
                    <div
                      className="flex gap-1 items-center w-[90%] cursor-pointer"
                      onClick={() => setReviewsPerPage(REVIEWS_PER_PAGE)}
                    >
                      <p
                        className="text-underline font-[600] text-[18px]"
                        style={{ textDecoration: "underline" }}
                      >
                        See less reviews
                      </p>
                      <PiCaretDownBold className="text-lg font-bold" />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {/* Related Product Section */}
        <div className="mt-10 mb-24 max-md:mt-5 max-md:mb-12">
          <div className="flex justify-between items-center">
            <div>
              <HeadingTitle title="Related Products" />
            </div>
            {isLoading && (
              <>
                <ProductItemMiniLoaders number={4} />
              </>
            )}

            <div>
              {fetchedProduct?.relatedProducts?.length >
                relatedProductsPerPage && (
                <ViewAllButton
                  onClick={() =>
                    setRelatedProductsPerPage(MAX_RELATED_PRODUCTS_PER_PAGE)
                  }
                >
                  <IoIosArrowRoundForward className="text-lg" />
                </ViewAllButton>
              )}
              {relatedProductsPerPage === MAX_RELATED_PRODUCTS_PER_PAGE && (
                <ViewAllButton
                  text="View few"
                  onClick={() =>
                    setRelatedProductsPerPage(RELATED_PRODUCTS_PER_PAGE)
                  }
                >
                  <IoIosArrowRoundForward className="text-lg" />
                </ViewAllButton>
              )}
            </div>
          </div>
          {!isLoading &&
            fetchedProduct?.relatedProducts &&
            fetchedProduct?.relatedProducts?.length === 0 && (
              <div className="flex items-center justify-center py-10">
                <CustomNoRecordFound
                  showButton={false}
                  buttonText={"Got it"}
                  label={"No Related Products Found"}
                >
                  <NotFoundSVG />
                </CustomNoRecordFound>
              </div>
            )}
          {fetchedProduct?.relatedProducts &&
            fetchedProduct?.relatedProducts?.length > 0 && (
              <RelatedProductsSection
                relatedProducts={fetchedProduct?.relatedProducts ?? []}
                mainProduct={fetchedProduct}
                relatedProductsPerPage={relatedProductsPerPage}
              />
            )}
        </div>
      </div>
    </Layout>
  );
}

export default Product;
